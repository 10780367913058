// Imports
// ------
import React, { useState, useEffect, useRef } from 'react';
import Logo from '@parts/Logo';
import ThemeToggle from './Toggle2';
import StartConvoButton from './StartConvoButton';
import { observer } from 'mobx-react';
import { Chat } from '@states/chat';
import { lightDarkMode } from '@states/darkmode';
// import { themeStyles } from '@theme';

// Styles
// ------
import {
    Jacket,
    BackHome
} from './styles';

// Component
// ------
const Header = () => {
    const prevScrollY = useRef(0);
    const [goingDown, setGoingDown] = useState(false);
    const [top, setTop] = useState(true);
    const headerRef = useRef();
    const [headerSize, setHeaderSize] = useState(60);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;
            const ap = currentScrollY > 50;

            if (prevScrollY.current < currentScrollY && !goingDown && ap) {
                setGoingDown(true);
                setTop(false);
            }
            if (prevScrollY.current > currentScrollY && goingDown && !ap) {
                setGoingDown(false);
                setTop(false);
            }

            if (window.scrollY <= 49) {
                setTop(true);
            }

            prevScrollY.current = currentScrollY;
        };

        const handleSize = () => {
            setHeaderSize(headerRef.current.offsetHeight);
        }

        handleSize();
    
        window.addEventListener("scroll", handleScroll, { passive: true });
        window.addEventListener("resize", handleSize);
    
        return () => {
            window.removeEventListener("scroll", handleScroll);
            window.removeEventListener("resize", handleSize);
        }
    }, [goingDown]);

    function scrollUp() {
        if(window.location.pathname === "/") {
            if (window.pageYOffset > 100){
                window.scrollTo({top: 0, behavior: 'smooth'});
            } 
        }
    }

    return (
        <Jacket 
            isDark={lightDarkMode.isDark}
            isScrolling={goingDown}
            isTop={top}
            isHidden={Chat.isActive}
            ref={headerRef}
            dropSize={headerSize / 10}
        >
            <BackHome fade to="/" aria-label="Go back to the Homepage" onClick={scrollUp}>
                <Logo />
            </BackHome>

            <StartConvoButton />

            <ThemeToggle />
        </Jacket>
    );
}

export default observer(Header);