// Imports
// ------
import styled from 'styled-components';

// Exports
// ------
export const Jacket = styled.svg`
    fill: ${props => props.theme.bc1};
    width: 12.5rem;
    height: 1rem;
`;