// Imports
// ------
import React from 'react';

// Styles
// ------
import {
    Jacket,
} from './styles';

// Component
// ------
const Logo = () => (
    <Jacket width="125" height="10" viewBox="0 0 125 10" xmlns="http://www.w3.org/2000/svg">
        <path d="M62.5504 0.0501709C63.7739 0.0501709 64.7935 0.301427 65.4562 0.602935V2.41198L64.7425 1.20595C64.2327 0.854191 63.3151 0.602935 62.4485 0.602935C60.2564 0.602935 58.9819 2.16072 58.9819 4.72354C58.9819 7.53761 60.5113 9.2964 62.6014 9.2964C63.468 9.2964 64.4366 9.04515 64.8445 8.79389L65.6091 7.58786L65.4562 9.34665C64.8445 9.59791 63.8759 9.84917 62.4994 9.84917C59.5936 9.84917 57.6055 7.68836 57.6055 4.92454C57.6055 2.16072 59.5427 0.0501709 62.5504 0.0501709Z"/>
        <path d="M90.1801 2.16077C90.1801 3.11554 90.8938 3.51755 92.2703 4.17082C93.6977 4.82408 95.1251 5.47735 95.1251 7.08539C95.1251 8.64318 93.9526 9.84921 91.9134 9.84921C90.537 9.84921 89.6703 9.5477 89.0586 9.29644V7.5879L89.8233 8.74368C90.2311 8.99494 90.9958 9.29644 91.8115 9.29644C93.1369 9.29644 94.0036 8.59293 94.0036 7.63815C94.0036 6.53262 93.6467 6.23112 91.6585 5.32659C90.3841 4.72358 89.2115 4.07031 89.2115 2.61303C89.2115 1.05524 90.588 0.100464 92.2193 0.100464C93.3918 0.100464 94.0545 0.301469 94.7173 0.602976V2.01001L94.1055 1.20599C93.6467 0.854233 92.984 0.602976 92.1683 0.602976C91.0468 0.602976 90.1801 1.30649 90.1801 2.16077Z"/>
        <path d="M104.658 0.753692L105.576 1.70847L105.066 0.200928H97.6738L97.1641 1.70847L98.0817 0.753692H100.733V9.34666L100.172 9.69841H102.517L102.007 9.34666V0.753692H104.658Z"/>
        <path d="M115.67 9.69837L115.058 9.29636L110.878 0.150635H109.757L110.521 1.85918L107.055 9.34662L106.545 9.74862H108.227L107.666 9.34662L108.278 7.98983H113.223V7.93958L113.886 9.34662L113.376 9.74862H115.67V9.69837ZM108.584 7.43707L110.827 2.61295L113.019 7.43707H108.584Z"/>
        <path d="M86.2039 0.602938L86.8156 0.200928H84.9294L85.6431 0.602938L83.2471 5.02505H83.1451L82.3295 3.61801L80.3413 0.200928H79.7805H79.3727H78.4551V0.30143C78.8119 0.30143 79.1178 0.552686 79.3217 0.904445L82.1765 5.67831V9.34666L81.6667 9.74867H84.0627L83.451 9.34666V5.67831L86.2039 0.602938Z"/>
        <path d="M124.031 9.14565H119.902V0.602938L120.513 0.200928H118.066L118.576 0.602938V9.34666L118.066 9.74867H121.38H123.827H124.388L124.898 8.24113L124.031 9.14565Z"/>
        <path d="M72.2365 4.824H71.4208L75.1932 9.84913H77.2833V9.74862C76.9265 9.74862 76.5696 9.49737 76.3147 9.14561L73.256 5.12551C74.5815 4.87425 75.703 4.01998 75.703 2.61295C75.703 1.00491 74.3266 0.150635 72.4913 0.150635H69.1777L69.6875 0.552645V9.29636L69.1777 9.69837H71.5737L70.962 9.29636V0.854152C70.962 0.854152 71.6247 0.75365 72.3384 0.75365C73.6639 0.75365 74.3266 1.35666 74.3266 2.81395C74.3266 3.96973 73.6129 4.77375 72.2365 4.824Z"/>
        <path d="M0 0.200928H1.12153V8.69339H6.47431V9.74867H0V0.200928Z"/>
        <path d="M9.5332 0.200928H10.6547V9.74867H9.5332V0.200928Z"/>
        <path d="M27.3242 6.03007V0.200928H28.4458V6.03007C28.4458 7.78887 29.3634 8.8944 30.9947 8.8944C32.677 8.8944 33.5436 7.78887 33.5436 6.03007V0.200928H34.6652V6.03007C34.6652 8.49239 33.4417 9.89942 30.9947 9.89942C28.7516 9.89942 27.3242 8.69339 27.3242 6.03007Z"/>
        <path d="M38.8457 0.200928H39.9673V9.74867H38.8457V0.200928Z"/>
        <path d="M44.3008 0.200928H47.6654C50.4182 0.200928 52.2535 2.11048 52.2535 4.92455C52.2535 7.93962 50.5202 9.69841 47.5634 9.69841H44.3008V0.200928ZM47.3085 8.59289C49.7045 8.59289 50.877 7.33661 50.877 4.9748C50.877 2.81399 49.7555 1.40696 47.4105 1.40696H45.5243V8.64314H47.3085V8.59289Z"/>
        <path d="M22.6849 8.29142C23.3986 7.43715 23.8575 6.33162 23.8575 4.92458C23.8575 1.95976 21.7673 -0.050293 19.0654 -0.050293C16.3636 -0.050293 14.2734 1.95976 14.2734 4.97483C14.2734 7.98991 16.3636 9.94971 19.0654 9.94971C20.187 9.94971 21.2066 9.59795 21.9712 8.99493C22.6849 9.5477 23.6535 10.0502 25.03 10.0502V9.04519C24.0104 9.04519 23.2457 8.74368 22.6849 8.29142ZM19.0145 8.89443C18.1478 8.89443 17.3831 8.59293 16.8224 8.14066L16.7714 8.09041C16.3126 7.7889 16.0067 7.98991 16.0067 7.98991L15.7518 7.63815C15.9048 7.53765 16.1087 7.38689 16.2616 7.33664C16.5165 7.18589 16.7714 7.08539 17.0263 7.03514C17.0773 7.03514 17.1792 6.98488 17.2302 6.98488C17.2302 6.98488 17.2302 6.98488 17.2812 6.98488C17.3322 6.98488 17.4341 6.98488 17.4851 6.93463H17.5361C17.5871 6.93463 17.689 6.93463 17.74 6.93463H17.791C17.8929 6.93463 17.9949 6.93463 18.0968 6.93463C19.5243 6.93463 20.3399 7.5879 21.1046 8.29142C20.5948 8.64318 19.8301 8.89443 19.0145 8.89443ZM21.7673 7.5879C20.8497 6.78388 19.9831 5.97986 18.0459 5.97986C17.0263 5.97986 16.3636 6.23112 15.6499 6.58287C15.4969 6.13061 15.395 5.6281 15.395 4.97483C15.395 2.81403 16.8224 1.05523 19.0145 1.05523C21.2066 1.05523 22.634 2.81403 22.634 4.97483C22.634 6.03011 22.3281 6.88438 21.7673 7.5879Z"/>
    </Jacket>
)

export default React.memo(Logo);