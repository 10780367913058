// Imports
// ------
import styled, { css } from "styled-components";
// import { breakup } from '@tackl';

// Exports
// ------
export const Switch = styled.button(props => css`
    transition: all 0.3s ${props.theme.bezzy};
    position: absolute;
    z-index: 900;
    background: none;
    cursor: pointer;

    top: 50%; right: 1.2rem;
    transform: translateY(-50%);

    span {
        position: relative;
        display: inline-block;

        border-radius: 50%;
        width: 16px;
        height: 16px;
        box-shadow: inset 6px -6px 0 0 ${props.theme.bc1};
        transform: scale(1) rotate(-2deg);
        transition: box-shadow .5s ease 0s, transform .4s ease .1s;

        opacity: 1;

        &:before {
            content: '';
            width: inherit;
            height: inherit;
            border-radius: inherit;
            position: absolute;
            left: 0;
            top: 0;
            transition: background .3s ease;
        }

        &:after {
            content: '';
            width: 4px;
            height: 4px;
            border-radius: 50%;
            margin: -2px 0 0 -2px;
            position: absolute;
            top: 50%;
            left: 50%;
            box-shadow: 0 -14px 0 ${props.theme.bc1}, 0 14px 0 ${props.theme.bc1}, 14px 0 0 ${props.theme.bc1}, -14px 0 0 ${props.theme.bc1}, 10px 10px 0 ${props.theme.bc1}, -10px 10px 0 ${props.theme.bc1}, 10px -10px 0 ${props.theme.bc1}, -10px -10px 0 ${props.theme.bc1};
            transform: scale(0);
            transition: all .5s ease;
        }
    }

    ${props.isActive && css`
        span {
            box-shadow: inset 6px -12px 0 0 ${props.theme.bc1};
            transform: scale(.5) rotate(0deg);
            transition: transform .5s ease .1s, box-shadow .2s ease 0s;

            &:before {
                background: ${props.theme.bc1};
                transition: background .2s ease .1s;
            }

            &:after {
                transform: scale(1.5);
                transition: transform .6s ease .15s;
            }
        }
    `}

    ${props.isHidden && css`
        opacity: 0;
        pointer-events: none;
    `}
`);
