// Imports
// ---------
import styled, { css } from "styled-components";
import { containerStyles } from './containerStyles';
import { imageStyles } from './imageStyles';
import { h1Styles, h2Styles, h3Styles, h4Styles, h5Styles, pStyles, spanStyles, emStyles } from "./typographyStyles";
import { listStyles, listItemStyles } from "./listStyles";
import { breakpointUp, breakpointDown, breakpointOnly } from './breakpoints';

// --------------
// 1. Containers
// --------------
export const Section = styled.section` ${containerStyles} `;
export const Div = styled.div` ${containerStyles} `;
export const Main = styled.main` ${containerStyles} `;
export const Article = styled.article` ${containerStyles} `;
export const Header = styled.header` ${containerStyles} `;
export const Footer = styled.footer` ${containerStyles} `;

// --------------
// 2. Images
// --------------
export const Img = styled.img` ${imageStyles} `;

// --------------
// 3. Typography
// --------------
export const H1 = styled.h1` ${h1Styles} `;
export const H2 = styled.h2` ${h2Styles} `;
export const H3 = styled.h3` ${h3Styles} `;
export const H4 = styled.h4` ${h4Styles} `;
export const H5 = styled.h5` ${h5Styles} `;
export const P = styled.p` ${pStyles} `;
export const Em = styled.em` ${emStyles} `;
export const Span = styled.span` ${spanStyles} `;

// --------------
// 4. List
// --------------
export const List = styled.ul` ${listStyles} `;
export const ListItem = styled.li` ${listItemStyles} `;

// --------------
// 4. Breakpoints
// --------------
export const breakup = breakpointUp;
export const breakdown = breakpointDown;
export const breakonly = breakpointOnly;

// --------------
// 5. Content Block
// --------------
export const ContentBlock = styled.div(props => css`

    h1 { ${h1Styles} }
    h2 { ${h2Styles} }
    h3 { ${h3Styles} }
    h4 { ${h4Styles} }
    h5 { ${h5Styles} }

    p { ${h1Styles} }

    span { ${spanStyles} }

    em { ${emStyles} }

    img { width: 100%; }

    a {
        position: relative;
        display: inline-block;

        font-family: ${props.theme.body};
        color: ${props.theme.bc1};

        transition: all 0.3s ease-in-out;
        background: ${props.theme.bc2};

        &:hover {
            cursor: pointer;
            &:after { transform: scaleY(1.1); }
        }

        &:after {
            content: '';
            position: absolute;
            z-index: 3;
            top: 0; left: 0; right: 0; bottom: 0;
            background: ${props.theme.bc1};
            mix-blend-mode: difference;
            transform: scaleY(0.02);
            transform-origin: center bottom;
            transition: all .25s ease;
        }
    }

    * {
        margin-bottom: 6rem;
        &:last-child { margin-bottom: 0; }
    }
`);