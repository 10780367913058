// Imports
// ------
import { css } from "styled-components";
import { breakpointUp } from './breakpoints';

// Exports
// --------------
// 1. Shared Heading Styles
// --------------
export const sharedHeadingStyles = css`
    font-weight: ${props => props.theme.reg};
    font-family: ${props => props.theme.heading};
`;

// --------------
// 2. Heading Styles
// --------------
export const h1Styles = css`
    ${sharedHeadingStyles}
    font-weight: ${props => props.theme.extraLight};
    font-size: 2.2rem;
    line-height: 3.4rem;

    ${breakpointUp.large`
        font-size: 3.6rem;
        line-height: 5.4rem;
    `}
`;

export const h2Styles = css`
    ${sharedHeadingStyles}
    font-weight: ${props => props.theme.book};
    font-size: 2.5rem;
    line-height: 2.5rem;
    text-transform: uppercase;

    ${breakpointUp.large`
        font-size: 4rem;
        line-height: 4rem;
    `}
`;

export const h3Styles = css`
    ${sharedHeadingStyles}
    font-size: 2.6rem;
    line-height: 3.8rem;

    ${breakpointUp.large`
        font-size: 4.8rem;
        line-height: 6rem;
    `}
`;

export const h4Styles = css`
    ${sharedHeadingStyles}
    font-size: 2.4rem;
    line-height: 3.6rem;

    ${breakpointUp.large`
        font-size: 3.6rem;
        line-height: 4.8rem;
    `}
`;

export const h5Styles = css`
    ${sharedHeadingStyles}
    font-size: 2rem;
    line-height: 3.2rem;

    ${breakpointUp.large`
        font-size: 2.4rem;
        line-height: 3.6rem;
    `}
`;

// --------------
// 3. Shared Body Styles
// --------------
const sharedBodyStyles = css`
    font-family: ${props => props.theme.body};
    font-weight: ${props => props.theme.reg};
    letter-spacing: 0.005em;
`;

// --------------
// 3. Body Styles
// --------------
export const pStyles = css`
    ${sharedBodyStyles}
    font-weight: ${props => props.theme.book};
    font-size: 1.4rem;
    line-height: 2.1rem;

    ${breakpointUp.large`
        font-size: 2rem;
        line-height: 3rem;
    `}

    ${props => props.small && css`
        font-size: 1.4rem;
        line-height: 2.1rem;

        ${breakpointUp.large`
            font-size: 1.4rem;
            line-height: 2.1rem;
        `}
    `}

    ${props => props.large && css`
        ${sharedHeadingStyles}
        font-weight: ${props => props.theme.light};
        font-size: 2.2rem;
        line-height: 3.4rem;

        ${breakpointUp.large`
            font-size: 3.6rem;
            line-height: 5.4rem;
        `}
    `}

    ${props => props.uppercase && css`
        text-transform: uppercase;
    `}

    ${props => props.fontAlt && css`
        font-family: ${props.theme.serif};
        font-weight: ${props.theme.bookItalic};
        font-style: italic;
        letter-spacing: 0.05rem;
    `}
`;

export const spanStyles = css`
    ${sharedBodyStyles}
    font-size: 1.6rem;
    line-height: 2.6rem;

    ${breakpointUp.large`
        font-size: 1.8rem;
        line-height: 3.2rem;
    `}
`;

export const emStyles = css`
    ${sharedBodyStyles}
    display: block;
    font-weight: ${props => props.theme.light};
    font-style: normal;
    font-size: 1.2rem;
    line-height: 1.6rem;
    text-transform: uppercase;

    ${breakpointUp.large`
        font-size: 1.4rem;
        line-height: 1.8rem;
    `}
`;