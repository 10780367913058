// Imports
// ------
import styled, { css } from 'styled-components';
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { breakup, Header } from '@tackl';

// Exports
// ------
const smallPos = `2.4rem`;
const largePos = `3.6rem`;

export const Jacket = styled(Header)`
    position: relative;
    transition: all 0.3s ${props => props.theme.bezzy};
    position: fixed;
    top: 0; left: 0; right: 0;
    z-index: 1200;

    display: flex;
    align-items: center;
    justify-content: space-between;

    opacity: ${props => props.isHidden ? 0 : 1};
    pointer-events: ${props => props.isHidden ? `none` : `all`};

    &:before {
        content: "";
        position: absolute;
        z-index: 0;
        top: -${props => props.dropSize}rem; left: 0; right: 0;
        height: ${props => props.dropSize}rem;
        box-shadow: 0 0 4rem 10rem ${props => props.theme.bc2};
        transition: all .1s ease-in-out;

        ${props => props.isTop && ` opacity: 0; `}
    }
    

    /* Border */
    &:after {
        content: "";
        position: absolute;
        z-index: 2;
        left: 50%;
        transform: translateX(-50%) scaleX(0);
        width: calc(100% - ${smallPos});
        bottom: 0;
        height: 1px;
        background: ${props => props.theme.bc1};

        transition: transform .3s ease-in-out;

        ${breakup.large` width: calc(100% - ${largePos}); `}

        ${props => props.isScrolling && css`
            transform: translateX(-50%) scaleX(1);
            transition: transform 1s ${props => props.theme.bezzy};
        `}
    }
`;

export const BackHome = styled(AniLink)(props => css`
    transition: all .25s ${props.theme.bezzy};
    position: relative;
    display: inline-block;
    padding: ${smallPos} 1.2rem;

    ${breakup.medium` padding: 2.4rem 1.8rem; `}
    ${breakup.large` &:hover { opacity: 0.5; } `}
`);