// Imports
// ------
import React, { useEffect } from 'react';
import { lightDarkMode } from '@states/darkmode';
import { observer } from 'mobx-react';

// Styles
// ------
import { Switch } from './styles';

// Component
// ------
const ThemeToggler = () => {
    const today = new Date();

    function setTheme() {
        if(today.getHours() >= 20 || today.getHours() < 8) {
            lightDarkMode.isDark = true
        } else {
            lightDarkMode.isDark = false
        }
    }

    function handleToggle() {
        lightDarkMode.isDark = !lightDarkMode.isDark;
    }

    useEffect(() => {
        setTheme();
    },[]);

    return (
        <Switch type="button" onClick={() => handleToggle()} isActive={!lightDarkMode.isDark} aria-label="Theme Switcher">
            <span />
        </Switch>
    );
}

export default observer(ThemeToggler);