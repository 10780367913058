// Imports
// ------
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Header from '@parts/Header';
import { lightDarkMode } from '@states/darkmode';
import { observer } from 'mobx-react';

// Styles
// ------
import '@styles/app.scss';
import { ThemeProvider } from 'styled-components';
import { lightTheme, darkTheme, GlobalStyle } from '@theme';
import { ParallaxProvider } from 'react-scroll-parallax';

// Component
// ------
const Layout = ({ children }) => {
	useEffect(() => {
		const setHeightVar = () => {
			let vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty('--vh', `${vh}px`);
		}

		setHeightVar();
		window.addEventListener('resize', setHeightVar);
	}, []);

	const themeChecker = !lightDarkMode.isDark ? lightTheme : darkTheme;

	return (
		<>
			<GlobalStyle theme={themeChecker} />

			<ThemeProvider theme={themeChecker}>
				<Header />
				<ParallaxProvider>{children}</ParallaxProvider>
			</ThemeProvider>
		</>
	);
}

export default observer(Layout);

Layout.defaultProps = {
	children: null,
};

Layout.propTypes = {
	children: PropTypes.node.isRequired,
};
