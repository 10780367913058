// Imports
// ------
import { observable } from 'mobx';

// Exports
export const Chat = observable({
    isActive: false,

    requestDeck: false,
    requestProposal: false,
    requestExpert: false,
    requestConversation: false,

    requestIndividual: false,
    personName: `Alex Levin`,
    personImage: `https://www.datocms-assets.com/31058/1594668087-mask-group-copy.jpg`,
});
