// Imports
// ------
import React from 'react';
import { Chat } from '@states/chat';

// Styles
// ------
import {
    ButtonJacket,
    Button
} from './styles';

// Component
// ------
const StartConvoButton = () => {
    function handleStart() {
        Chat.isActive = !Chat.isActive;
        Chat.requestConversation = true;
    }

    return (
        <ButtonJacket>
            <Button onClick={() => handleStart()} type="button" aria="Start a conversation">Start a conversation</Button>
        </ButtonJacket>
    );
}

export default StartConvoButton;