// Imports
// ------
import styled from 'styled-components';
import { breakup } from '@tackl';

// Exports
// ------
export const ButtonJacket = styled.div`
    display: none;

    ${breakup.medium`
        display: block;
        position: absolute;
        top: 50%; right: 7rem;
        transform: translateY(-50%);
    `}
`;

export const Button = styled.button`
    display: inline-block;
    padding: 0;
    margin: 0;
    background: none;

    text-transform: uppercase;
    font-size: 1.2rem;
`;