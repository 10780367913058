// Imports
// ------
import { css } from 'styled-components';

// Exports
// ------
export const listStyles = css`
    list-style: none;
`;

export const listItemStyles = css`
    display: block;

    > a { display: block; }
`;