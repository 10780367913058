module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[],"layout":"/opt/build/repo/src/layouts/index.jsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"small":"(max-width: 370px)","smedium":"(max-width: 768px)","medium":"(max-width: 1024px)","large":"(max-width: 1200px)","xlarge":"(max-width: 1440px)","xxlarge":"(max-width: 1600px)","huge":"(max-width: 2000px)","uber":"(max-width: none)","portrait":"(orientation: portrait)","horizontal":"(orientation: horizontal)"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-W662WN3","includeInDevelopment":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"liquid-crystal","short_name":"lc","start_url":"/","background_color":"#000000","theme_color":"#ffffff","display":"minimal-ui","icon":"src/images/lc-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"0c9cab3160c591aff55acfb1d600805f"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
